<template>
	<div class="innovate" v-title="'创新工艺——千家美装饰室内装修公司_房屋装修设计_家装设计报价'">
		<NavBar></NavBar>
		<div class="box">
			<img class="bran" src="../image/build/header_pc.jpg" alt="" />
		</div>
        <div class="box-mob">
            <img
				class="bran"
				src="../image/build/header_mob.jpg"
				alt=""
			/>
		</div>
		<div class="content f-y-s-c">
			<div class="box1">
				<div class="header f-y-c-c">
					<span class="text1">十九大创新工艺</span>
					<span class="text2">细心打磨匠心工艺更安全可靠</span>
				</div>
                <div class="box-list1">
                    <div 
                        v-for="(item, index) in list1"
						:key="index"
                        :style="`background: no-repeat center/cover url(${item.url})`"
                        class="item f-x-c-c">
                        <div class="mask"></div>
                        <div class="btn f-x-c-c">{{item.title}}</div>
                    </div>
				</div>
                <div class="box-list2">
                    <div 
                        v-for="(item, index) in list2"
						:key="index"
                        class="item f-y-s-s">
                        <span class="text1">{{item.title}}</span>
                        <span class="text2">{{item.desc}}</span>
                        <span class="text3">{{item.desc2}}</span>
                    </div>
				</div>
			</div>
            <div class="box2 f-y-s-c">
                <div class="pc-box">
                    <div class="top-box f-x-c-c">
                        <VisitorForm2
                            title="获取装修方案"
                            desc="一站式装修拎包即入住服务"
                            :showArea="false"
                            :showHouseType="false"
                            :showCity="false"
                            adUnitCode="uDB2"
                        ></VisitorForm2>
                    </div>
                    <div class="bottom f-y-c-c">
                        <div class="header f-y-c-c">
                            <span class="text1">科学合理化整装工期</span>
                            <span class="text2">硬核标准，一尺一寸一厘必精算测量，每个交付节点的质量验收均严格把控</span>
                        </div>
                        <div class="img-box">
                            <img class="img" src="../image/build/innovate_box2_bg.jpg" alt="">
                        </div>
                    </div>
                </div>
                <div class="mob-box f-y-s-c">
                    <div class="top-box f-x-c-c">
                        <VisitorForm2
                            title="获取装修方案"
                            desc="一站式装修拎包即入住服务"
                            class="form"
                            :showArea="false"
                            :showHouseType="false"
                            :showCity="false"
                            adUnitCode="uDB2"
                        ></VisitorForm2>
                    </div>
                    <div class="bottom f-y-c-c">
                        <div class="header f-y-c-c">
                            <span class="text1">科学合理化整装工期</span>
                            <span class="text2">硬核标准，一尺一寸一厘必精算测量，每个交付节点的质量验收均严格把控</span>
                        </div>
                        <div class="list f-y-c-e">
                            <div class="list-item item1 f-x-s-c">
                                <span class="title">工序</span>
                                <div class="line"></div>
                                <span class="text3">固定节拍，流水施工</span>
                            </div>
                            <div class="list-item item2 f-x-s-c">
                                <span class="title">工艺</span>
                                <div class="line"></div>
                                <span class="text3">高标准工艺，匠心品质</span>
                            </div>
                            <div class="list-item item3 f-x-s-c">
                                <span class="title">工法</span>
                                <div class="line"></div>
                                <span class="text3">严苛淬炼，层层把关，塑造卓越品质</span>
                            </div>
                            <div class="list-item item4 f-x-s-c">
                                <span class="title">工料</span>
                                <div class="line"></div>
                                <span class="text3">入仓+出仓双重检验，质量无忧</span>
                            </div>
                            <div class="list-item item5 f-x-s-c">
                                <span class="title">工检</span>
                                <div class="line"></div>
                                <span class="text3">一次校验合格</span>
                            </div>
                            <div class="list-item item6 f-x-s-c">
                                <span class="title">工保</span>
                                <div class="line"></div>
                                <span class="text3">成品专业保护，让新家从里美到外</span>
                            </div>
                        </div>
                        <div class="img-box f-x-c-c">
                            <div class="btn">六工合一</div>
                        </div>
                    </div>
                </div>
				
			</div> 
		</div>
		<!-- 底部 -->
		<Footer></Footer>

        <!-- 右浮窗和底部吸顶 -->
        <Floating></Floating>
        <FloatingMob></FloatingMob>
        <VisitorForm3></VisitorForm3>
        <Tabbar></Tabbar>
	</div>
</template>
<script>
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import VisitorForm2 from '../components/VisitorForm2.vue'
import { postmobile } from '@/api/views/name.js'
export default {
	components: {
		NavBar,
		Footer,
        VisitorForm2
	},
	created() {},
	mounted() {},
	data() {
		return {
			list1: [
				{
					url: require('../image/build/innovate_box1_1.jpg'),
                    title:'水电工艺'
				},
				{
					url: require('../image/build/innovate_box1_2.jpg'),
                    title:'泥瓦工艺'
				},
				{
					url: require('../image/build/innovate_box1_3.jpg'),
                    title:'木工工艺'
				},
				{
					url: require('../image/build/innovate_box1_4.jpg'),
                    title:'油料工艺'
				},
				
			],
            list2: [
				{
					desc: '安全防护 远离触电危险',
                    title:'360°防护工艺'
				},
				{
					desc: '瓷砖黏贴效果更好，空间使用率更高',
                    title:'空间1+1工艺'
				},
				{
					desc: '隔离楼上下水管水流声还你安静的空间',
                    title:'橫无声工艺'
				},
				{
					desc: '卫生间会被湿气入侵房间干爽舒适',
                    title:'门边泛水隔离工艺'
				},
                {
					desc: '不会影响网络等弱电信号信号满格的安全感',
                    title:'抗干扰工艺'
				},
				{
					desc: '整屋墙面挂网格布防止墙面油漆开裂',
                    title:'零开裂挂网工艺'
				},
				{
					desc: '卫生间安装双重防臭地漏给你的卫生间不一样',
                    title:'双重防臭工艺'
				},
				{
					desc: '提高热效率节约能源更为你省钱',
                    title:'热能保护工艺'
				},
                {
					desc: '墙边阳角采用护角条使墙角不会因磕碰而受损',
                    title:'阳角零伤害工艺'
				},
				{
					desc: '高规格材料高规格安装工艺高规格成品确保',
                    title:'顶面零开裂工艺'
				},
				{
					desc: '每一块地板都要五龙悬浮铺贴好脚感更要',
                    title:'五龙悬浮竹炭包工艺'
				},
				{
					desc: '横竖十字交叉的防水工艺无缝防水盛水不漏',
                    title:'盛水不漏工艺'
				},
                {
					desc: '回形切割+2倍坡度无死角迅速下水更舒心',
                    title:'2*2极速下水'
				},
				{
					desc: '新建墙体拉墙筋为你打造安 全稳固的港湾',
                    title:'双排连接工艺'
				},
				{
					desc: '浴室柜背部加插口式挂板45°加固承重柜',
                    title:'45°加固保护'
				},
				{
					desc: '大圆弧排管抽拉灵活不伤线替换维修更便捷',
                    title:'月亮弯工艺'
				},
                {
					desc: '黄墙绿地清爽干净全景实样 提前看家里',
                    title:'1:1数字放样'
				},
				{
					desc: '玄关一键开关',
					desc2: '整屋智能享受',
                    title:'智能一键开关',
				},
				{
					desc: '洗手台洗碗池3秒出热水暖流由指尖直达全',
                    title:'3秒热水工艺'
				},
			],
			
		}
	},
	methods: {
	},
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .innovate {
        overflow-x: hidden;
		.box {
            display: none;
			
		}
        .box-mob{
            width: 100%;
			height: 200px;
			margin-top: 43px;
			.bran {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
        }
        .content {
			// padding: 0 180px 70PX 180PX;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #ffffff;
            .box1{
                width: 100%;
                padding: 0 12px;
                box-sizing: border-box;
                margin-top: 50px;
                .header{
                    .text1{
                        
                        font-weight: bold;
                        font-size: 24px;
                        color: #120523;
                    }
                    .text2{
                        margin-top: 5px;
                        
                        font-weight: 400;
                        font-size: 12px;
                        color: #444444;
                    }
                }
                .box-list1{
                    display: grid;
                    justify-content: space-between;
                    grid-template-columns: repeat(2,1fr);
                    gap: 10px 10px;
                    width: 100%;
                    margin-top: 20px;
                    .item{
                        width: 100%;
                        height: 100px;
                        border-radius: 10px;
                        overflow: hidden;
                        // margin-bottom: 30px;
                        position: relative;
                        .mask{
                            width: 100%;
                            height: 100px;
                            background: #1B1818;
                            border-radius: 10px;
                            opacity: 0.3;
                            position: absolute;
                            left: 0;
                            top: 0;
                        }
                        .btn{
                            width: 100px;
                            height: 40px;
                            border-radius: 20px;
                            border: 1px solid #b6b6b6;
                            // opacity: 0.56;
                            
                            font-weight: 400;
                            font-size: 16px;
                            color: #FFFFFF;
                            z-index: 9;
                        }
                    }
                }
                .box-list2{
                    display: grid;
                    margin-top: 20px;
                    justify-content: space-between;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 10px 10px;
                    width: 100%;
                    .item{
                        background: url(../image/build/box1_item_bg.png);
                        background-size: 103% 103%;
                        background-repeat: no-repeat;
                        width: 100%;
                        height: 70px;
                        border-radius: 5px;
                        border: 1px solid #EDEDED;
                        padding: 5px;
                        box-sizing: border-box;
                        .text1{
                            
                            font-weight: bold;
                            font-size: 16px;
                            color: #120523;
                        }   
                        .text2{
                            
                            font-weight: 400;
                            font-size: 12px;
                            color: #444444;
                            margin-top: 3px;
                            line-height: 15px;
                        }
                        .text3{
                            
                            font-weight: 400;
                            font-size: 12px;
                            color: #444444;
                            line-height: 15px;
                            // margin-top: 2px;
                        }
                    }
                }
            }
            .box2{
                margin-top: 152px;
                width: 100%;
                .pc-box{
                    display: none;
                }
                .mob-box{
                    width: 100%;
                    .top-box{
                        background:no-repeat center/cover   url(../image/build/innovate_box2_bg2.png);
                        padding:0 25px;
                        width: 100%;
                        height: 306px;
                        box-sizing: border-box;
                        .form{
                            margin-top: -266px;
                        }
                    }
                    .bottom{
                        margin-top: 50px;
                        width: 100%;
                        // height: 400px;
                        background: #FFFFFF;
                        .header{
                            width: 100%;
                            padding: 0px 25px;
                            box-sizing: border-box;
                            .text1{
                                
                                font-weight: bold;
                                font-size: 24px;
                                color: #120523;
                                
                            }
                            .text2{
                                margin-top: 10px;
                                text-align: center;
                                
                                font-weight: 400;
                                font-size: 12px;
                                color: #444444;
                            }
                        }
                        .list{
                            width: 100%;
                            margin-top: 26px;
                            .list-item{
                                box-sizing: border-box;
                                height: 42px;
                                background: #753CBE;
                                border-radius: 21px;
                                padding: 0 20px;
                                margin-bottom: 13px;
                                border-radius:  20px 0 0 20px;
                                .title{
                                    
                                    font-weight: bold;
                                    font-size: 17px;
                                    color: #EDEDED;
                                    line-height: 17px;
                                }
                                .line{
                                    margin: 0 10px;
                                    width: 1px;
                                    height: 14px;
                                    background: #EDEDED;
                                }
                                .text3{
                                    
                                    font-weight: 400;
                                    font-size: 13px;
                                    color: #EDEDED;
                                    line-height: 17px;
                                }
                            }
                            .item1{
                                width: 287px;
                            }
                            .item2{
                                width: 302px;
                            }
                            .item3{
                                width: 317px;
                            }
                            .item4{
                                width: 332px;
                            }
                            .item5{
                                width: 347px;
                            }
                            .item6{
                                width: 362px;
                            }
                        }
                        .img-box{
                            margin-top: 5px;
                            width: 100%;
                            height: 274px;
                            background:center/cover url(../image/decoration/whole_box5_bg2.jpg);
                            .btn{
                                width: 138px;
                                height: 48px;
                                background: #753CBE;
                                border-radius: 24px;
                                
                                font-weight: bold;
                                font-size: 24px;
                                color: #FFFFFF;
                                line-height: 48px;
                                text-align: center;
                            }
                        }
                    }
                }
                
            }
        }
    }
}
@media screen and (min-width: 900px) {
    .innovate{
        overflow-x: hidden;
        .box{
            width: 100%;
			height: 420px;
			// margin-top: 96px;
            .bran {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .box-mob{
            display: none;
        }
        .content {
			// padding: 0 180px 70PX 180PX;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #ffffff;
            .box1{
                width: 100%;
                padding: 0 183px;
                box-sizing: border-box;
                margin-top: 130px;
                .header{
                    .text1{
                        
                        font-weight: bold;
                        font-size: 72px;
                        color: #120523;
                    }
                    .text2{
                        margin-top: 25px;
                        
                        font-weight: 400;
                        font-size: 30px;
                        color: #444444;
                    }
                }
                .box-list1{
                    display: grid;
                    justify-content: space-between;
                    grid-template-columns: repeat(2,1fr);
                    gap: 20px 20px;
                    width: 100%;
                    margin-top: 50px;
                    .item{
                        background-size: 100% 100% !important;
                        background-repeat: no-repeat !important;
                        width: 760px;
                        height: 360px;
                        border-radius: 20px;
                        overflow: hidden;
                        // margin-bottom: 30px;
                        position: relative;
                        .mask{
                            width: 760px;
                            height: 360px;
                            background: #1B1818;
                            border-radius: 20px;
                            opacity: 0.3;
                            position: absolute;
                            left: 0;
                            top: 0;
                        }
                        .btn{
                            width: 331px;
                            height: 97px;
                            border-radius: 48px;
                            border: 2px solid #b6b6b6;
                            // opacity: 0.56;
                            
                            font-weight: 400;
                            font-size: 54px;
                            color: #FFFFFF;
                            z-index: 9;
                        }
                    }
                }
                .box-list2{
                    display: grid;
                    margin-top: 50px;
                    justify-content: space-between;
                    grid-template-columns: repeat(4, 1fr);
                    gap: 30px 30px;
                    width: 100%;
                    
                    .item{
                        background: url(../image/build/box1_item_bg.png);
                        background-size: 103% 103%;
                        background-repeat: no-repeat;
                        width: 365px;
                        height: 181px;
                        border-radius: 10px;
                        border: 1px solid #EDEDED;
                        padding: 25px 32px;
                        box-sizing: border-box;
                        .text1{
                            
                            font-weight: bold;
                            font-size: 30px;
                            color: #120523;
                        }   
                        .text2{
                            
                            font-weight: 400;
                            font-size: 24px;
                            color: #444444;
                            margin-top: 12px;
                        }
                        .text3{
                            
                            font-weight: 400;
                            font-size: 24px;
                            color: #444444;
                        }
                    }
                }
            }
            .box2{
                margin-top: 200px;
                height: 1300px;
                .mob-box{
                    display: none;
                }
                .pc-box{
                    box-sizing: border-box;
                    background: url(../image/build/innovate_box2_bg2.png);
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    padding:0 180px;
                    width: 100%;
                    height: 100%;
                    .top-box{
                        // width: 100%;
                        margin-top: -110px;
                    }
                    .bottom{
                        margin-top: 47px;
                        width: 1556px;
                        height: 1000px;
                        background: #FFFFFF;
                        border-radius: 18px;
                        padding: 68px 130px;
                        box-sizing: border-box;
                        .header{
                            .text1{
                                
                                font-weight: bold;
                                font-size: 72px;
                                color: #120523;
                            }
                            .text2{
                                margin-top: 25px;
                                
                                font-weight: 400;
                                font-size: 30px;
                                color: #444444;
                            }
                        }
                        .img-box{
                            margin-top: 60px;
                            height: 650px;
                            width: 100%;
                            .img{
                                height: 100%;
                                width: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }
               
                
                .box-list{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-top: 50px;
                    .item{
                        width: 270px;
                        height: 120px;
                        background: #FFFFFF;
                        border: 1px solid #E7E7E7;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}
</style>
